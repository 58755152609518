@media print {
    .desktop-menu {
        display: none;
    }
    .menu-mobile {
        display: none;
    }

    .content-w {
        background-color: white;
    }

    .element-box {
        border: 0px solid #000000;
    }

    .content-box {
        padding: 0;
    }

    a {
        text-decoration: none !important;
    }
}
