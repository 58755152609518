.DateRangePicker_picker {
  z-index: 10
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #0073ff;
  border: 1px double #0073ff;
  ;
}

.CalendarDay__selected_span,
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #98c9fd;
  border: 1px double #82bfff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover {
  background: #98c9fd;
  border: 1px double #82bfff;
  color: white;
}

.DateInput_input__focused {
  border-bottom: 2px solid #0073ff;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #0073ff;
}