.loading-container {
    position: relative;
}

.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
}

.loading-spinner {
    width: 8%;
}

.form-template-link {
    color: #334152;
}
